<template>
<div class="administrationtargets-container">
    <slideout-panel></slideout-panel>
    <vs-dialog v-model="batchPublishDialog">
        <p>Il target verrà sostituito in tutti i POi appartenente al target che si intende eliminare.</p>
        <p>Questa operazione potrebbe richiedere diverso tempo.</p>
        <p v-if="batchPublishMessage != null">{{ batchPublishMessage }}</p>
        <LazyDropdown v-model="batchPublishValue" :value="batchPublishValue" endpoint="/targets" :multiple="false" />
        <vs-button size="large" v-if="batchPublishWaitingConfirm" block @click="batchPublishConfirm" :loading="batchPublishLoading"> {{ $t('common.continue') }}</vs-button>
        <vs-button size="large" v-else block @click="batchPublishStart" :loading="batchPublishLoading"> {{ $t('common.continue') }}</vs-button>
    </vs-dialog>
    <vs-row justify="flex-end" style="width:100%;">
        <vs-button size="large" @click="createNew">Crea Nuovo</vs-button>
    </vs-row>

    <vs-table v-model="selected" style="height: 80vh; width: 100%; margin-top: 20px;">
        <template #thead>
            <vs-tr>
                <vs-th style="width: 10%;">

                </vs-th>
                <vs-th>
                    Name
                </vs-th>
            </vs-tr>
        </template>
        <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in targets" :data="tr" :is-selected="selected == tr">
                <vs-td>
                    <img style="width: auto; height: 64px;" :src="baseUri + '/targets/' + tr.id + '/picture?t=' + Date.now()" @error="() => {tr.noPicture = true}" v-if="!tr.noPicture">

                </vs-td>
                <vs-td>
                    {{ tr.localized.find(e => e.language_id == user.language.id).name }}
                </vs-td>
            </vs-tr>
        </template>
        <template #footer>
            <vs-row justify="center" align="center">
                <vs-button :disabled="result == null || result.before == null" @click="previousPage">
                    <img src="../../assets/icons/previous_page.png" />
                </vs-button>
                <vs-button :disabled="result == null || result.after == null" @click="nextPage">
                    <img src="../../assets/icons/next_page.png" />
                </vs-button>
            </vs-row>

        </template>
    </vs-table>
</div>
</template>

<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
import LazyDropdown from '../../components/LazyDropdown.vue';

import EditTarget from '../../components/elements/EditTarget.vue';
export default ({
    components: {
        LazyDropdown
    },
    data() {
        return {
            targets: [],
            selected: null,
            filters: {},
            result: null,
            panel: null,
            baseUri,

            batchPublishDialog: false,
            batchPublishValue: null,
            batchPublishPois: [],
            batchPublishLoading: false,
            batchPublishMessage: null,
            batchPublishWaitingConfirm: false
        }
    },
    props: {
        user: {
            type: Object,
            required: false
        }
    },

    mounted() {
        this.getTargets(this.filters)
    },

    methods: {
        createNew() {
            this.selected = {
                id: null,
                name: '',

            }
        },
        previousPage() {
            this.filters.before = this.result.before;
            this.filters.after = null;
            this.filters.__ob__.dep.notify();
        },

        nextPage() {
            this.filters.after = this.result.after;
            this.filters.before = null;
            this.filters.__ob__.dep.notify();
        },

        async getTargets(p) {
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/backoffice/targets', p);
            loading.close();
            // if status code is 200, show a success message 
            if (response.status == 200) {
                console.log(response.data.data)
                this.targets = response.data.data;
                this.result = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile caricare i Target',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async deleteTarget(id) {
            const loading = this.$vs.loading();
            const response = await apiCall('DELETE', '/backoffice/targets/' + id);
            loading.close();
            // if status code is 200, show a success message 
            if (response.status == 200) {
                this.getTargets();
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Target eliminato con successo',
                    color: 'success',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare il Target',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async editTarget(target) {
            const response = await apiCall(target.id == null ? 'POST' : 'PATCH', '/backoffice/targets' + (target.id != null ? ('/' + target.id) : ''), target);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (target.id == null) {
                    this.selected.id = response.data.id;
                }

                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Target modificato con successo',
                    color: 'success',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile modificare il Target',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async batchPublishStart() {
            this.batchPublishWaitingConfirm = false;
            this.batchPublishLoading = true;
            await this.getAllPois({target_ids: [this.selected.id]});
            this.batchPublishMessage = 'Verranno modificati ' + this.batchPublishPois.length + ' POI, vuoi proseguire?';
            this.batchPublishWaitingConfirm = true;
            this.batchPublishLoading = false;

        },

        async editPoi(poi, silent = false) {
            // use apiCall to make a request to /pois
            poi.localized = poi.poi_localization_infos;
            const response = await apiCall(poi.id == null ? 'POST' : 'PATCH', '/backoffice/pois' + (poi.id != null ? ('/' + poi.id) : ''), poi);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (poi.id == null) { // ho fatto la creazione
                    this.selected.id = response.data.id;
                    // necessario riottenere tutte le informazioni complete
                    this.selected.poi_localization_infos = (await this.getPoiDetail(response.data.id)).localized;
                }
                if (!silent) {
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: 'POI modificato con successo',
                        color: 'success',
                        position: 'top-right'
                    });
                }
                return true;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile modificare il POI',
                    color: 'danger',
                    position: 'top-right'
                });

            }
            return false;
        },

        async batchPublishConfirm() {
            this.batchPublishLoading = true;
            this.batchPublishPois = this.batchPublishPois.map(e => ({
                ...e,
                target_id: this.batchPublishValue
            }));
            var s = true;
            for (var i = 0; i < this.batchPublishPois.length; i++) {
                this.batchPublishMessage = 'Pubblicazione ' + (i + 1) + ' di ' + this.batchPublishPois.length + ' POI';
                if (!await this.editPoi(this.batchPublishPois[i], true)) {
                    s = false;
                    this.batchPublishMessage = 'Errore durante la pubblicazione di ' + (i + 1) + ' di ' + this.batchPublishPois.length + ' POI';
                    break;
                }
            }
            if (s) {
                this.batchPublishMessage = 'Pubblicazione di ' + this.batchPublishPois.length + ' POI completata';
                await this.deleteTarget(this.selected.id);
                await this.panel.hide();
                this.panel = null;
                this.batchPublishDialog = false;
            }

            this.batchPublishLoading = false;
        },

        async getAllPois(p) {
            if (!this.batchPublishDialog) {
                return;
            }
            // that match filters
            
            // use apiCall to make a request to /pois
            const response = await apiCall('GET', '/backoffice/pois', p);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                console.log(response.data.data)
                if (response.data.data != null && response.data.data.length > 0) {
                    this.batchPublishPois = this.batchPublishPois.concat(response.data.data.map(e => ({
                        ...e,
                        point: {
                            x: e.longitude,
                            y: e.latitude
                        },
                        poi_localization_infos: e.localized
                    })));
                    this.batchPublishMessage = 'Modificati 0 di ' + this.batchPublishPois.length + ' POI';
                }

                if (response.data.after != null) {
                    return await this.getAllPois({
                        ...p,
                        after: response.data.after
                    })
                } else {
                    return this.batchPublishPois;
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.messages.unableToLoadPOIs'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },
    },

    watch: {
        filters(n) {
            this.getTargets(n);
        },
        async selected(n) {
            if (n == null) {
                return;
            }
            console.log(n)
            var vm = this;

            if (this.panel != null) {
                await this.panel.hide();
                this.panel = null;
            }
            this.panel = await this.$showPanel({
                component: EditTarget,
                openOn: 'right',
                props: {
                    target: n,
                    editTarget: this.editTarget,
                    user: this.user,
                    deleteTarget: () => {this.batchPublishDialog = true;},
                }
            });
            console.log(this.panel)

            this.panel.promise
                .then(() => {
                    vm.selected = null;
                });

        },

        batchPublishDialog() {
            this.batchPublishValue = false;
            this.batchPublishPois = [];
            this.batchPublishMessage = null;
            this.batchPublishWaitingConfirm = false;
        },
    },
})
</script>

<style scoped>
.administrationtargets-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
</style>
