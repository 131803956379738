<template>
<div class="editTarget-container">
    <div class="top">

        <div class="fields">
            <div class="field" v-if="target.id != null">
                <p>{{ $t('common.language') }}</p>
                <vs-select v-model="lang" v-if="languages.length > 0">
                    <vs-option v-for="l in languages" :key="'lang-' + l.id" :label="l.name" :value="l.id">
                        {{ l.name }}
                    </vs-option>
                </vs-select>
            </div>


            <div class="field">
                <p>Nome</p>
                <vs-input v-if="lang != null" type="text" v-model="target.localized.find(e => e.language_id == lang).name" />
            </div>

            <img :src="baseUri + '/targets/' + target.id + '/picture?t=' + t" @error="pictureNotFound" v-if="!noPicture && target.id != null">


            <input type="file" ref="targetPictureInput" @change="fileChanged" style="display: none" accept="image/*">
            <vs-button size="large" :loading="loading" @click="editTargetEvent">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>
            <vs-button size="large" v-if="target.id != null" :loading="uploading" @click="$refs.targetPictureInput.click()">CARICA ICONA </vs-button>
            <vs-button size="large" border danger @click="deleteTarget()">ELIMINA</vs-button>

        </div>
    </div>

</div>
</template>

    
<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
export default {
    name: "editTarget",
    props: {
        target: {
            type: Object,
            required: true
        },
        editTarget: {
            type: Function,
            required: true
        },

        user: {
            type: Object,
            required: false,
            default: null
        },

        deleteTarget: {
            type: Function,
            required: false,
            default: null
        }
    },
    components: {},
    data() {
        return {
            loading: false,
            languages: [],
            lang: null,
            t: Date.now(),
            noPicture: false,
            baseUri
        }
    },

    async mounted() {
        const response = await apiCall('GET', '/users/languages');
        if (response.status != 200) {
            this.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: this.$t('common.messages.somethingWentWrong'),
                text: 'Impossibile ottenere le lingue'
            })
            return;
        }
        this.languages = response.data.language;
        this.lang = this.languages.find(e => e.id == this.user.language.id).id

        if (this.target.id == null) {
            this.target.localized = [{
                language_id: this.lang,
                name: '',
            }]
        }
    },

    methods: {

        pictureNotFound() {
            this.noPicture = true;
        },


        async fileChanged(e) {
            var file = e.target.files[0];
            // use apiCall to call the upload api passing a payload with the base64 image
            this.uploading = true;
            const response = await apiCall('PUT', '/backoffice/targets/' + this.target.id + '/picture', {
                file
            }, false, 'multipart/form-data');
            // if status code is 200 show a success message
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Immagine caricata con successo',
                    color: 'success',
                    position: 'top-right'
                });
                this.noPicture = false;
                this.t = Date.now();
            } else if(response.status == 413){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.imageTooLarge'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile caricare l\'immagine, riprova più tardi',
                    color: 'danger',
                    position: 'top-right'
                });
            }
            this.uploading = false;

        },

        async editTargetEvent() {

            if (this.target.id == null) {
                for (var k in this.languages) {
                    if (this.languages[k].id == this.lang) {
                        continue;
                    }
                    this.target.localized.push({
                        language_id: this.languages[k].id,
                        name: this.target.localized.find(e => e.language_id == this.lang).name,
                    })
                }
            }
            this.loading = true;
            await this.editTarget(this.target);
            this.loading = false;

        },

    }
}
</script>

    
<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.edittarget-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .edittarget-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;

    }

}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}
</style><style>
.edittarget-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.edittarget-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.edittarget-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}
</style>
